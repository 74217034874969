import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./modal.css"

function Welcome(args) {
    const [modal, setModal] = useState(true);
  
    const toggle = () => setModal(!modal);
  
    return (
      <>
        <Modal id="welcome" centered={true} isOpen={modal} toggle={toggle} {...args}>
          <ModalBody> <button type="button" className="close" onClick={toggle}>
         &times;
        </button>
          <p>At the Purina Institute, we believe science is more powerful when it's shared. That's why we're on a mission to unlock the power of nutrition to help pets live better, longer lives. As a global professional organization, the Purina Institute shares Purina's leading-edge research and evidence-based information from the wider scientific community in an accessible, actionable way with a goal of empowering veterinary professionals to put nutrition at the forefront of pet health discussions to further improve and extend the healthy lives of pets through nutrition.
         <br/><br/>
         We invite you to explore our scientific exhibit, which highlights the science behind Purina's innovations and provides a glimpse into how truly powerful nutrition can be. </p>
          </ModalBody>
        </Modal>
      </>
    );
  }
  
  export default Welcome;
  