import React from "react";
export const Micrio = ({micrioId}) => {

  return (
      <micr-io
        id={micrioId}
        ui="true"
        controls="false"
        logo="false"
        minimap="false"
      >
      </micr-io>
  );
};
