import React from "react";
import "./mini-map.css";

export const MiniMap = (mapLinks) => {
 console.log(mapLinks)
    const handleClick = () => (e) => {
    mapLinks.mapLinks.forEach((el) => el.classList.remove("active"));
    e.currentTarget.classList.add("active");
    mapLinks.micrioId.setMicrioId(e.currentTarget.id);
    
  };

 
    return (
    <>
      <div className="mini-map-container">
        <div className="mini-map">
          <button
            id="ULLxM"
            className="mini-map-link"
            onClick={handleClick()}
            title="Auditorium"
          />
          <button
            id="ZZKQV"
            className="mini-map-link"
            onClick={handleClick()}
            title="new-area-entry"
          />

          <button
            id="UVQFp"
            className="mini-map-link"
            onClick={handleClick()}
            title="Extending a Healthy Life"
          />
          <button
            id="WTlEs"
            className="mini-map-link"
            onClick={handleClick()}
            title="The Future of Nutrition"
          />
          <button
            id="NCYpB"
            className="mini-map-link"
            onClick={handleClick()}
            title="Microbiome"
          />
          <button
            id="fDNHH"
            className="mini-map-link"
            onClick={handleClick()}
            title="A History of Innovation"
          />
          <button
            id="cSiWb"
            className="mini-map-link"
            onClick={handleClick()}
            title="Advancing Brain Health"
          />
          <button
            id="SOiOd"
            className="mini-map-link"
            onClick={handleClick()}
            title="Welcome"
          />
          <button
            id="rPxhD"
            className="mini-map-link"
            onClick={handleClick()}
            title="Meet Purina"
          />
          {/* <button
            id="tVQsm"
            className="mini-map-link"
            onClick={(e) => handleClick(e)}
            title="Entrance"
          /> */}
        </div>
      </div>
    </>
  );
};
