import React, { useState } from "react";
import { Header } from "./components/Header";
import { MapButton } from "./components/MapButton";
import Welcome from "./components/Welcome";
import "./App.css";
import "./micrio.css";
import { Micrio } from "./components/MicrioTour";

function App() {
  const [micrioId, setMicrioId] = useState("NYWZQ");

  return (
    <>
      <Welcome />
      <Header />
      <Micrio micrioId={micrioId} />
      <MapButton micrioId={micrioId} setMicrioId={setMicrioId} />
    </>
  );
}

export default App;
