import React, { useState, useEffect } from "react";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import { MiniMap } from "./MiniMap";
import "./map-button.css";

export const MapButton = (micrioId) => {
  const [collapse, setCollapse] = useState(false);
  const [status, setStatus] = useState("Closed");
  //eslint-disable-next-line
  const [popover, setPopover] = useState(false);

  const onEntering = () => setStatus("Opening...");
  const onEntered = () => setStatus("Opened");
  const onExiting = () => setStatus("Closing...");
  const onExited = () => setStatus("Closed");
  const exhibit = document.querySelector(".exhibit");
  const toggle = () =>
    exhibit
      ? exhibit.classList.contains("opened")
        ? setPopover(true)
        : setCollapse(!collapse)
      : setCollapse(!collapse);
  console.log(exhibit);

  const mapLinks = Array.from(document.getElementsByClassName("mini-map-link"));
  console.log(mapLinks);
  useEffect(() => {
    const image = document.querySelector("micr-io");

    image.addEventListener("marker-open", (e) => {
      console.log(e.detail.data);
      mapLinks.forEach((el) => el.classList.remove("active"));
      mapLinks.forEach((el) =>
        e.detail.data.micrioLink === null
          ? setCollapse(false)
          : e.detail.data.micrioLink.id === el.id
          ? el.classList.add("active")
          : null
      );
    });
    return () => {};
  }, [mapLinks, micrioId.micrioId, micrioId.micrioId.id]);
  return (
    <>
      <Collapse
        style={{ height: "fit-content" }}
        isOpen={collapse}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
        onExited={onExited}
        horizontal
      >
        <Card className="map-card">
          <CardBody>
            <MiniMap mapLinks={mapLinks} micrioId={micrioId} />
          </CardBody>
        </Card>
      </Collapse>
      <Button
        className="map-drawer-toggle-button"
        onClick={toggle}
        style={{ marginBottom: "1rem" }}
      >
        <h4>{status === "Opened" ? "Close Map" : "Show Map"}</h4>
      </Button>
    </>
  );
};
